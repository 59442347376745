import React, { useState } from "react";
import { AppWrap, MotionWrap } from "../../wrapper";
import { AiFillEye, AiFillGithub } from "react-icons/ai";
import { motion } from "framer-motion";
import "./Work.scss";
import { FilterWorks } from "../DataApi";
import ReactTooltip from "react-tooltip";

const Work = () => {
  const [activeFilter, setActiveFilter] = useState("All");
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });
  const [filterWork, setFilterWork] = useState(FilterWorks);

  const handleWorkFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);
      if (item === "All") {
        setFilterWork(FilterWorks);
      } else {
        const filteredList = FilterWorks.filter((work) => work.tags === item);

        setFilterWork(filteredList);
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text margin-top">
        My Proof of <span> Work </span>
      </h2>

      <div className="app__work-filter">
        {["UI/UX", "Web3 DApp", "Web App", "All"].map((item, index) => (
          <div
            key={index}
            onClick={() => handleWorkFilter(item)}
            className={`app__work-filter-item app__flex p-text ${
              activeFilter === item ? "item-active" : ""
            }`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__work-portfolio"
      >
        {filterWork.map((work, index) => (
          <>
            <div
              className="app__work-item app__flex"
              key={work.tags + index}
              data-tip
              data-for={work.description}
            >
              <div className="app__work-img app_flex">
                <img src={work.imgUrl} alt={work.name} loading="lazy" />

                <motion.div
                  whileHover={{ opacity: [0, 1] }}
                  transition={{
                    duration: 0.25,
                    ease: "easeInOut",
                    staggerChildren: 0.5,
                  }}
                  className="app__work-hover app__flex"
                >
                  <a href={work.projectLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{
                        duration: 0.25,
                      }}
                      className="app__flex"
                    >
                      <AiFillEye />
                    </motion.div>
                  </a>
                  <a href={work.codeLink} target="_blank" rel="noreferrer">
                    <motion.div
                      whileInView={{ scale: [0, 1] }}
                      whileHover={{ scale: [1, 0.9] }}
                      transition={{
                        duration: 0.25,
                      }}
                      className="app__flex"
                    >
                      <AiFillGithub />
                    </motion.div>
                  </a>
                </motion.div>
              </div>

              <div className="app__work-content app__flex">
                <h4 className="bold-text">{work.title}</h4>
                <p className="p-text" style={{ marginTop: 10 }}>
                  {work.description}
                </p>
                <div className="app__work-tag app__flex">
                  <p className="p-text">{work.tags}</p>
                </div>
              </div>
            </div>

            <ReactTooltip
              id={work.description}
              effect="solid"
              arrowColor="#fff"
              className="skills-tooltip"
            >
              {work.tech}
            </ReactTooltip>
          </>
        ))}
      </motion.div>
    </>
  );
};
export default AppWrap(
  MotionWrap(Work, "app__works"),
  "work",
  "app__primarybg"
);
