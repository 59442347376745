import React, { useState } from "react";
import { motion } from "framer-motion";
import { images } from "../../constants";
import { AppWrap } from "../../wrapper";
import "./Header.scss";
import sendEmail from "../../components/MailsendApi";

const scaleVariants = {
  whileInView: {
    scale: [0, 1],
    opacity: [0, 1],
    tranistion: {
      duration: 1,
      ease: "easeInOut",
    },
  },
};

const imgBgContainer = {
  hidden: { scale: 0 },
  show: {
    scale: 1,
    transition: {
      staggerChildren: 0.5,
      delayChildren: 0.5,
    },
  },
};

const imgBG = {
  hidden: { scale: 0 },
  show: { scale: 1 },
};



const Header = () => {
  const [email, setEmail] = useState("");
  const [isReq, setIsRequired] = useState(false)
  const handleChangeInput = (e) => {
    setEmail(e?.target?.value);

  }

  const handleSubmit = () => {
    if(email) {
      setIsRequired(true);
      sendEmail("", email, "web3crafters subscriber")
    }

  }

  return (
    <div className="app__header app__flex">
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-info"
      >
        <div className="app__header-badge">
          <div className="badge-cmp app__flex">
            <span>👋</span>
            <div style={{ marginLeft: 20 }}>
              <p className="p-text">Hello,</p>
              <h3 className="head-text">WE BUILD YOUR DREAMS</h3>
          
            </div>
          </div>

          {/* <div className="tag-cmp app__flex">
            <p className="p-text">DEVELOPERS of 𝗪𝗘𝗕𝟮 & 𝗪𝗘𝗕𝟯</p>
          </div> */}
          {!isReq ? <div className="app__header-badge">
            <div className="app__footer-form app__flex new_form">
              <h3 className="head-text">Bootstrap your next web3 project</h3>
              <span></span>
              <div className="app__flex">
                <input
                  type="email"
                  className="p-text topEmail"
                  placeholder="Your Email or Contact number"
                  name="email"
                  value={email}
                  onChange={handleChangeInput}
                />
              </div>
              <button type="button" className="p-text" onClick={handleSubmit} >
              Let’s Go!
              </button>
            </div>
          </div>:
          <div className="app__header-badge">
          <div className="app__footer-form app__flex new_form">
            <h3 className="head-text">Will connect Soon</h3>
          </div>
        </div>
          }
        </div>

      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1] }}
        transition={{ duration: 0.5 }}
        className="app__header-img"
      >
        <img src={images.rohitprofile2} alt="profile_bg" loading="lazy" />
        <motion.div
          variants={imgBgContainer}
          initial="hidden"
          animate="show"
          className="overlay_circle"
        >
          <motion.img
            variants={imgBG}
            src={images.circle}
            alt="profile_circle"
            className="profileIcon"
          />
          <motion.div
            variants={imgBG}
            className="overlay_circle extra-circle1"
          />
          <motion.div
            variants={imgBG}
            className="overlay_circle extra-circle2"
          />
          <motion.div
            variants={imgBG}
            className="overlay_circle extra-circle3"
          />
        </motion.div>
      </motion.div>

      <motion.div
        variants={scaleVariants}
        whileInView={scaleVariants.whileInView}
        className="app__header-circles"
      >
        {[images.solidity, images.react, images.thegraph].map(
          (circle, index) => (
            <div className="circle-cmp app__flex" key={`circle-${index}`}>
              <img src={circle} alt="circle" loading="lazy" />
            </div>
          )
        )}
      </motion.div>
    </div>
  );
};

export default AppWrap(Header, "home");
